import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core"

import { Link } from "gatsby";
import styled from 'styled-components';

const LINK_HEIGHT = '65px';


export const MobileNavbarContainer = styled.div`
    @media (min-width: 886px){
        display:none;
    }
    width:100%;
    display: flex;
    justify-content:space-between;
    align-items:center;
    
`;
export const Logo = styled(Link)`
    margin-right: 20px;
    min-width: 200px;
    align-self: center;
`; 
export const MobileNavbarDrawer = styled.div`
    padding:100px 0px 20px;
    height:100%;
    min-width:75vw;
    display: flex;
    flex-flow: column;
    justify-content:space-between;
`;

export const MobileMenu = styled.div`
    .MuiAccordion-root:before{
        display: none;
    }
    width:100%;
`;
export const MobileSubmenu = styled.div`
    display:flex;
    flex-flow:column;
    width: 100%;
`;

export const MobileMenuHeader = styled.h5`
    font-weight: bold;
    padding: 0 25px;
    font-size:var(--fontSize-1);
    letter-spacing:.19px;
`;

export const MobileSubmenuLink = styled(Link)`
    &.navbar__submenu-link--active {
        color: var(--color-primary);
    }
    padding: 10px 25px;
    white-space: nowrap;
    color: var(--color-secondary);
    margin: 0;
    min-height:${LINK_HEIGHT};
    width:100%;
    font-size:var(--fontSize-1);
    letter-spacing:.19px;
    display:flex;
    align-items:center;
    border-top:1px solid rgba(67, 41, 163, .1);
    &:first-child{
        border-top:2.5px solid var(--color-primary);
    }
`;

export const QuickActions = styled.div`
    display: flex;
    justify-content:center;
    width:100%;
    padding: 40px 20px 10px;
`;

export const SignInButton = styled.a`
    min-width:100px;
    border-radius: 50px;
    padding: 7px 10px;
    border: 1px solid rgba(67, 41, 1663, .34);
    background-color:white;
    font-family: var(--font-body);
    color:black;
    font-weight:bold;
    display:flex;
    justify-content:center;
    align-items:center;
    margin: 0 5px;
    svg{
        margin-right: 5px;
    }
    @media(min-width: 450px){
        padding: 7px 25px;
    }
`;

export const GetStartedButton = styled(Link)`
    min-width:100px;
    border-radius: 50px;
    padding: 7px 10px;
    font-family: var(--font-body);
    font-weight:bold;
    display:flex;
    justify-content:center;
    align-items:center;
    margin: 0 5px;
    background-color:var(--color-primary);
    color:white;
    border:none;
    @media(min-width: 450px){
        padding: 7px 25px;
    }
`;

export const StyledAccordion = styled(Accordion)`
    // Increase specificity with '&&&' to override styles from MUI
    &&& {
        box-shadow: none;
        background-color: transparent;
        margin:0;
    }
`;

export const Panel = styled(AccordionSummary)`
    &&& {
        min-height:${LINK_HEIGHT};
        padding-left: 0;
    }
    &:after{
        top: -1px;
        left: 0;
        right: 0;
        height: 1px;
        content: "";
        opacity: 1;
        position: absolute;
        transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: rgba(0, 0, 0, 0.12);
    }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
    &&& {
        margin: 0;
        padding: 0;
    }
`;
