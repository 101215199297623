import { BiChevronDown, BiMenu, BiX } from "react-icons/bi"
import { Cta, LeftHalf, Logo, Menu, MenuContainer, MenuHeader, NavbarContainer, RightHalf, StyledNavbar, Submenu, SubmenuLink } from "./Navbar.styles"
import { GetStartedButton, MobileMenu, MobileMenuHeader, MobileNavbarContainer, MobileNavbarDrawer, MobileSubmenu, MobileSubmenuLink, Panel, QuickActions, SignInButton, StyledAccordion, StyledAccordionDetails } from "./MobileNavbar.styles"
import React, { useContext, useState } from "react"
import { AnalyticsContext } from "../../../context"
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import { FaUser } from "react-icons/fa"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { StyledChevron } from "../../../reusable.styles"
import { getPageSlug } from "../../../../helpers";

// import NotificationBanner from '../NotificationBanner'


function Navbar() {
  const { handleTealiumEvent } = useContext(AnalyticsContext)
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [expanded, setExpanded] = useState('none');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  /**
   * TODO: Set this up with a general object array to control all of the menu items and loop through that to make the desktop and mobile menus so we don't need to duplicate everything on both menus
   * */

  return (
    <>
      <StyledNavbar>
        <NavbarContainer>
          <LeftHalf>
            <Logo to="/" >
              <StaticImage
                src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1710408215/Grubhub%20Corporate%20Website/Logo/HEADER_GH_CorporateAccounts_Horizontal_sRGB_Orange.png"
                alt="Grubhub/Seamless Corporate Accounts"
                placeholder="blurred"
                width={196}
              />
            </Logo>
            <MenuContainer>
              <Menu>
                <MenuHeader>Solutions <BiChevronDown size={25}/></MenuHeader>
                <Submenu>
                  <SubmenuLink
                    to="/how-grubhub-works/"
                    activeClassName="navbar__submenu-link--active"
                    onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "solutions", eventlabel: "how it works" })}
                  >How it works</SubmenuLink>
                  <SubmenuLink
                    to="/ways-to-order/"
                    activeClassName="navbar__submenu-link--active"
                    onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "solutions", eventlabel: "ways to order" })}
                  >Ways to order</SubmenuLink>
                  <SubmenuLink
                    to="/ordering-occasions/"
                    activeClassName="navbar__submenu-link--active"
                    onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "solutions", eventlabel: "ordering occasions" })}
                  >Ordering occasions</SubmenuLink>
                </Submenu>
              </Menu>
              <Menu>
                <MenuHeader>Resources <BiChevronDown size={25}/></MenuHeader>
                <Submenu>
                  <SubmenuLink
                    to="/contact-us/"
                    activeClassName="navbar__submenu-link--active"
                    onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "resources", eventlabel: "contact us" })}
                  >Contact us</SubmenuLink>
                  <SubmenuLink
                    to="/blog/"
                    activeClassName="navbar__submenu-link--active"
                    onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "resources", eventlabel: "blog" })}
                  >Blog</SubmenuLink>
                  <SubmenuLink
                    to="/our-clients/"
                    activeClassName="navbar__submenu-link--active"
                    onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "resources", eventlabel: "our clients" })}
                  >Our clients</SubmenuLink>
                  <SubmenuLink
                    to="/faqs/"
                    activeClassName="navbar__submenu-link--active"
                    onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "resources", eventlabel: "faq" })}
                  >FAQs</SubmenuLink>
                  <SubmenuLink
                    to="/learning-center/"
                    activeClassName="navbar__submenu-link--active"
                    onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "resources", eventlabel: "learning center" })}
                  >Learning center</SubmenuLink>
                </Submenu>
              </Menu>
            </MenuContainer>
          </LeftHalf>
          <RightHalf>
            <a
              style={{color:"var(--color-secondary)"}}
              href="https://www.grubhub.com/login?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_corporate-client-help-site&utm_content=nopromo_header-navigation"
              onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "sign in", eventlabel: "header" })}
            ><FaUser size={15}/> Sign in</a>
            <Link
              to="/get-started/"
              onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "get started_cta", eventlabel: "header" })}
            >
              <Cta>Get started</Cta>
            </Link>
          </RightHalf>

          {/* MOBILE */}
          <MobileNavbarContainer>
            <Logo to="/" >
              <StaticImage
                src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652230775/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Homepage/LOGO-GH-SL-Corp.png"
                alt="Grubhub/Seamless Corporate Accounts"
                placeholder="blurred"
                width={196}
              />
            </Logo>
            <Button onClick={() => setDrawerIsOpen(!drawerIsOpen)}>
              { drawerIsOpen ? <BiX size={35} aria-label="Open Menu" /> : <BiMenu size={35} aria-label="Open Menu" /> }
            </Button>

            <Drawer anchor={"right"} open={drawerIsOpen} onClose={() => setDrawerIsOpen(false)}>
              <MobileNavbarDrawer>
                <MobileMenu>
                  <StyledAccordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <Panel aria-controls="panel1d-content" id="panel1d-header" expandIcon={<StyledChevron/>}>
                      <MobileMenuHeader>Solutions</MobileMenuHeader>
                    </Panel>
                    <StyledAccordionDetails>
                      <MobileSubmenu>
                        <MobileSubmenuLink
                          to="/how-grubhub-works/"
                          activeClassName="navbar__submenu-link--active"
                          onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "solutions", eventlabel: "how it works" })}
                        >
                          How it works
                        </MobileSubmenuLink>
                        <MobileSubmenuLink
                          to="/ways-to-order/"
                          activeClassName="navbar__submenu-link--active"
                          onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "solutions", eventlabel: "ways to order" })}
                        >
                          Ways to order
                        </MobileSubmenuLink>
                        <MobileSubmenuLink
                          to="/ordering-occasions/"
                          activeClassName="navbar__submenu-link--active"
                          onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "solutions", eventlabel: "ordering occasions" })}
                        >
                          Ordering occasions
                        </MobileSubmenuLink>
                      </MobileSubmenu>
                    </StyledAccordionDetails>
                  </StyledAccordion>
                  <StyledAccordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <Panel aria-controls="panel2d-content" id="panel2d-header" expandIcon={<StyledChevron/>}>
                      <MobileMenuHeader>Resources</MobileMenuHeader>
                    </Panel>
                    <StyledAccordionDetails>
                      <MobileSubmenu>
                        <MobileSubmenuLink
                          to="/contact-us/"
                          activeClassName="navbar__submenu-link--active"
                          onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "resources", eventlabel: "contact us" })}
                        >
                          Contact us
                        </MobileSubmenuLink>
                        <MobileSubmenuLink
                          to="/blog/"
                          activeClassName="navbar__submenu-link--active"
                          onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "resources", eventlabel: "blog" })}
                        >
                          Blog
                        </MobileSubmenuLink>
                        <MobileSubmenuLink
                          to="/our-clients/"
                          activeClassName="navbar__submenu-link--active"
                          onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "resources", eventlabel: "our clients" })}
                        >
                          Our clients
                        </MobileSubmenuLink>
                        <MobileSubmenuLink
                          to="/faqs/"
                          activeClassName="navbar__submenu-link--active"
                          onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "resources", eventlabel: "faq" })}
                        >
                          FAQs
                        </MobileSubmenuLink>
                        <MobileSubmenuLink
                          to="/learning-center/"
                          activeClassName="navbar__submenu-link--active"
                          onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "resources", eventlabel: "learning center" })}
                        >
                          Learning center
                        </MobileSubmenuLink>
                      </MobileSubmenu>
                    </StyledAccordionDetails>
                  </StyledAccordion>
                </MobileMenu>
                <QuickActions>
                  <SignInButton
                    href="https://www.grubhub.com/login?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_corporate-client-help-site&utm_content=nopromo_header-navigation"
                    onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "sign in", eventlabel: "header" })}
                    ><FaUser size={15}/> Sign in</SignInButton>
                  <GetStartedButton
                    to="/get-started/"
                    onClick={() => handleTealiumEvent({ eventcategory: "header interaction", eventaction: "get started_cta", eventlabel: "header" })}
                    >Get started</GetStartedButton>
                </QuickActions>
              </MobileNavbarDrawer>
            </Drawer>
          </MobileNavbarContainer>
        </NavbarContainer>
        {/* TODO: Finish the Notification banner and uncomment */}
        {/* <NotificationBanner/> */}
      </StyledNavbar>
    </>
  )
}

export default Navbar
