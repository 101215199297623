import { Link } from "gatsby";
import { queries } from "../../../breakpoints"
import styled from 'styled-components';

const NAVBAR_HEIGHT = "100px"


export const NavbarContainer = styled.div`
    background-color: white;
    padding: 0 var(--spacing-6);
    height: ${NAVBAR_HEIGHT};
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    z-index: 999;
    width: 100%;
`;

export const LeftHalf = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
`;

export const RightHalf = styled.div`
    flex: auto;
    display: flex;
    justify-content: flex-end;
    align-items:center;
    a{
        font-weight: bold;
    }
`;

export const Logo = styled(Link)`
    margin-right: 20px;
    min-width: 200px;
    align-self: center;
    @media (${queries.max_break_sm}){
      min-width: unset;
      
    }
`;

export const MenuContainer = styled.div`
    display: flex;
    align-content: center;
`;

export const Submenu = styled.div`
    display: none;
    position: absolute;
    top: 100%;
    z-index: 9;
    background-color: white;
    min-width: 150px;
    font-size: var(--fontSize-1);
    font-weight: normal;
    border: 1px solid rgba(67, 41, 163, 0.1);
    border-top: 4px solid var(--color-primary);
    flex-flow: column;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.3);
    -moz-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.3);
    box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.3);
    &:hover {
        display: flex;
    }
`;

export const Menu = styled.div`
    display: flex;
    align-content: center;
    position: relative;
    &:hover ${Submenu} {
        display: flex;
    }
`;

export const MenuHeader = styled.p`
    color: var(--color-secondary);
    margin: auto 15px;
    background-color: transparent;
    border: none;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    font-family: var(--font-body);
    svg {
        margin-left: 3px;
    }

    @media(max-width: 930px){
        margin: auto 5px;
    }

`;

export const SubmenuLink = styled(Link)`
    &.navbar__submenu-link--active {
        color: var(--color-primary);
    }
    padding: 10px 15px;
    white-space: nowrap;
    color: var(--color-secondary);
    margin: 0;
    font-family: var(--font-body);
    font-weight: var(--fontWeight-light);
    &:not(:first-child) {
        border-top: 1px solid rgba(67, 41, 163, 0.1);
    }
`;

export const StyledLink = styled.button`
    margin: auto 15px;
    white-space: nowrap;
    color: var(--color-secondary);
    font-family: var(--font-body);
    border:none;
    background-color:transparent;
    font-weight:bold;
    display:flex;
    justify-content:center;
    align-items:center;
    svg{
        margin-left:7px;
    }
`;

export const Cta = styled(StyledLink)`
    background-color: var(--color-primary);
    padding: 5px 15px;
    border-radius: 100px;
    color: white;
    transition: .1s all;
    &:hover{
        color:white;
        background-color:var(--color-primary-dark);
    }
    &:active{
        background-color: var(--color-primary-darker);
    }
`;


export const StyledNavbar = styled.header`
    -webkit-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    padding: 0;
    z-index: 2000;
    position: relative;
    @media (max-width: 885px){
        ${NavbarContainer}{
            align-items:center;
        }
        ${LeftHalf}, ${RightHalf}{
            display: none;
        }
    }
`;